import React, { useEffect, useState } from "react";
import "./AboutPage.css";
import omerImage from "../images/website_Omer-10.png";
import omerImageMobile from "../images/website_Omer-40.png";
import ResearchArea from "../components/ResearchArea ";

function AboutPage() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="body">
      <div className="about-page">
        <div className="content">
          <div className="about-section">
            <h2 className="orange-title">ABOUT</h2>
            <p>
              I am Omer Ben Simhon, a political communication scholar in the
              excellence program at the Smart Institute for Advanced Studies in
              Communication at the Hebrew University of Jerusalem.
            </p>
            <p>
              With a background in communications consulting, I developed a keen
              interest in political persuasion, which has become the focus of my
              research. My thesis examines the causal effect of motivated
              reasoning on interpersonal persuasion, which I have had the
              privilege of presenting at major international conferences. Moving
              forward, I plan to explore psycho-physiological measures and
              emotional responses to interpersonal persuasion. My work has been
              honored with several awards, including the "Future in Hebrew"
              Scholarship, the SPARK Award from the American Political Science
              Association, the Dean's Prize, the Rector's Prize, the Prof.
              Shamir Prize for Excellence in Political Communication Research,
              and the Shachal Prize for Outstanding Research Paper from the
              Israel Communication Association.
            </p>
            <p>
              In addition to my thesis work, I am actively involved in the
              Experimental Political Communication Lab, funded by the ISF, where
              we study the effects of extreme political rhetoric on individuals’
              emotions, attitudes, and behavior. This research group, led by Dr.
              Eran Amsalem, also investigates how political similarities or
              differences affect individuals’ willingness to engage in
              persuasive dialogue, especially during conflicts such as the
              Israel-Hamas war.
            </p>
            <p>
              I completed my B.A. summa cum laude at Sapir College, where I
              wrote a seminar on the attitudes and usage patterns of Israelis
              regarding social media use during weddings, which I presented at
              various conferences.
            </p>
            <p>
              Beyond my academic pursuits, I have managed the Israel
              Sociological Association's social media pages and founded the
              social platforms for the journal "Media Frames" (the Israeli
              Journal of Communication). I also serve as a teaching assistant in
              the Department of Communication and Journalism at the Hebrew
              University of Jerusalem and the Department of Communication
              at Sapir College
            </p>
          </div>
          <div className="image-section">
            <img
              src={isMobile ? omerImageMobile : omerImage}
              alt="Omer's Image"
              aria-label="Portrait of the business owner, Omer"
            />
          </div>
        </div>
        <div className="research-component">
          <ResearchArea />
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
